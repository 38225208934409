import { Component, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @ViewChild('dataHeder') heder;
  @ViewChild('dataFooter') footer;
  public title: string = 'cashflow';
  public login: string ="asdas";  
  public user:any = {
    name: "",
    phone: ""
  };
  //public dateContact: any;

  constructor(private http: HttpClient) { 
  };

  ngOnInit() {
    this.getDate();
  }

  forma(){
    let  baseUrl:string = 'https://xn-----6kclhceaj4amntcrap9c5j.xn--p1ai/telegram/?'+'name='+this.user.name+'&phone='+this.user.phone;
    //console.log(baseUrl);
    this.http.get(baseUrl).subscribe(data => {
      console.log(data);      
    });  
  }

  getDate(){
    let  baseUrl:string = 'https://xn-----6kclhceaj4amntcrap9c5j.xn--p1ai/data/date.php';
    this.http.get(baseUrl).subscribe(( jsonData: any )  => {
      this.heder.initData(jsonData);
      this.footer.initData(jsonData);
    });  
  }
}
