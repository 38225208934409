import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { SoglashenieComponent } from './soglashenie/soglashenie.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'soglashenie', component: SoglashenieComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { 

  
}
