import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public arText: any = [
    "Для чего нужны деньги!?  Где они Живут? От куда они Приходят!? Какие ваши любимые каналы денег!?",
    "Зажимы и страхи из детства, которые мешают пройти Энергии Денег! Обязательно выявим и уберём!",
    "Негативное отношение к деньгам и людям у кого много денег! Выявим все страхи и с помощью специальных практик поменяем отношение с негативного на доброе и позитивное!",
    "Боязнь потери денег, которая есть у 90% людей, поменяем на знание для чего это нужно! Уберём страх потери денег и создадим инструмент расширения денежного канала!",
    "Целебная Денежная река! Погрузимся в медитацию для расширения денежного потока!",
    "Денежный Магнит! Напишем ВАУ Планы по деньгам, на которые как магнитом будут притягиваться деньги!",
    "Деньги, как сообщающиеся сосуды! Чем больше у меня ответственности в Мире, тем больше Денег ко мне приходит! Чем большему количеству Людей в Мире я готов принести пользу, тем больше будет Денежный Поток, проходящий через меня!"
  ];

  public arText1: any = [
    "ВАУ Денежная Ракета! Вдохновляющие Проекты, которые дают Драйв! От которых идёт Энергия и увеличивается Поток Денег!!!",
    "Благотворительность! Благодарность Вселенной за открытие Денежного Потока! Создание личного благотворительного проекта!",
    "Простукивание по меридианам! Практика, которой пользуются тибетские монахи более 5 000 лет для открытия в Себе Канала Денежного Потока!",
    "Денежный дождь! Осыпание вас настоящими новыми денежными купюрами для того, чтобы вы на уровне тела привыкли к большому денежному потоку! Прочувствовали Состояние Радости и Счастья общения с МЕГА Денежным Потоком Вселенной!!! После Тренинга обрели свой личный большой Денежный Поток для воплощения своей Мечты!",
  ];
  constructor() { }

  ngOnInit() {    
   // window.addEventListener('scroll', this.scroll, true);
  }

  scroll = () =>{
    this.fadeInRight(document.getElementById("text1"));
    this.fadeInLeft(document.getElementById("text2"));
    this.fadeInRight(document.getElementById("text3"));
    this.fadeInLeft(document.getElementById("text4"));
    this.fadeInRight(document.getElementById("text5"));
  };

  fadeInRight(element){
    if(element.offsetTop  <= window.pageYOffset + screen.height){
      element.style.opacity = "1";
      element.classList.add("fadeInRight");
      element.classList.add("animated");
    }
  }

  fadeInLeft(element){
    if(element.offsetTop  <= window.pageYOffset + screen.height){
      element.style.opacity = "1";
      element.classList.add("fadeInLeft");
      element.classList.add("animated");
    }
  }
  

  


}
